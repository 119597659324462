import React, { useState, useEffect } from 'react';
import { MenuItem, Select, Button, TextField, Typography } from '@mui/material';
import { getLendersFromFirestore } from '../services/lenderService';

const ExistingLenderDropdown = ({ userId, onTransaction }) => {
  const [lenders, setLenders] = useState([]);
  const [selectedLenderId, setSelectedLenderId] = useState('');
  const [transactionType, setTransactionType] = useState('');
  const [transactionAmount, setTransactionAmount] = useState('');

  useEffect(() => {
    const unsubscribe = getLendersFromFirestore(userId, setLenders);
    return () => unsubscribe();
  }, [userId]);

  const handleTransaction = () => {
    if (transactionAmount && transactionType && selectedLenderId) {
      onTransaction(selectedLenderId, transactionType, parseFloat(transactionAmount));
      setTransactionType('');
      setTransactionAmount('');
      setSelectedLenderId('');
    } else {
      alert("Please fill in all fields.");
    }
  };

  return (
    <div>
      <Typography variant="h6">Add Transaction for Existing Lender</Typography>
      <Select
        value={selectedLenderId}
        onChange={(e) => setSelectedLenderId(e.target.value)}
        displayEmpty
        style={{ marginRight: '10px', width: '200px' }}
      >
        <MenuItem value="" disabled>
          Select Lender
        </MenuItem>
        {lenders.length > 0 ? (
          lenders.map((lender) => (
            <MenuItem key={lender.id} value={lender.id}>
              {lender.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem value="" disabled>
            No Lenders Available
          </MenuItem>
        )}
      </Select>
      <Select
        value={transactionType}
        onChange={(e) => setTransactionType(e.target.value)}
        displayEmpty
        style={{ marginRight: '10px' }}
      >
        <MenuItem value="" disabled>
          Select Transaction
        </MenuItem>
        <MenuItem value="pay">Pay</MenuItem>
        <MenuItem value="borrow">Borrow</MenuItem>
      </Select>
      <TextField
        type="number"
        value={transactionAmount}
        onChange={(e) => setTransactionAmount(e.target.value)}
        placeholder="Amount"
        style={{ marginRight: '10px' }}
      />
      <Button onClick={handleTransaction} variant="contained" color="primary">
        Add Transaction
      </Button>
    </div>
  );
};

export default ExistingLenderDropdown;
