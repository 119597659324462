// src/services/lenderService.js
import { db } from '../firebase';
import { collection, addDoc, onSnapshot, doc, updateDoc, deleteDoc, getDoc, arrayUnion } from 'firebase/firestore';

const lendersCollectionRef = collection(db, 'lenders');

export const addLenderToFirestore = async (lender, userId) => {
  try {
    await addDoc(lendersCollectionRef, { ...lender, userId });
  } catch (error) {
    console.error("Error adding lender: ", error);
  }
};

export const getLendersFromFirestore = (userId, setLenders) => {
  if (!userId) {
    console.error("userId is required");
    return;
  }

  return onSnapshot(lendersCollectionRef, (snapshot) => {
    const lendersData = snapshot.docs
      .map(doc => ({ id: doc.id, ...doc.data() }))
      .filter(lender => lender.userId === userId);

    // Call setLenders with the new lenders data
    if (typeof setLenders === 'function') {
      setLenders(lendersData || []); // Ensure it's an array
    } else {
      console.error("setLenders is not a function");
    }
  });
};


export const markLenderAsPaidInFirestore = async (lenderId) => {
  const lenderDocRef = doc(db, 'lenders', lenderId);
  try {
    await updateDoc(lenderDocRef, { isPaid: 1 });
  } catch (error) {
    console.error("Error marking lender as paid: ", error);
  }
};

export const markLenderAsUnpaidInFirestore = async (lenderId) => {
  const lenderDocRef = doc(db, 'lenders', lenderId);
  try {
    await updateDoc(lenderDocRef, { isPaid: 0 });
  } catch (error) {
    console.error("Error marking lender as unpaid: ", error);
  }
};

export const editLenderInFirestore = async (lenderId, updatedLender) => {
  console.log("Editing lender with ID:", lenderId); // Log the ID
  const lenderDocRef = doc(db, 'lenders', lenderId);
  try {
    await updateDoc(lenderDocRef, updatedLender);
  } catch (error) {
    console.error("Error updating lender: ", error);
  }
};


export const deleteLenderFromFirestore = async (lenderId) => {
  const lenderDocRef = doc(db, 'lenders', lenderId);
  try {
    await deleteDoc(lenderDocRef);
  } catch (error) {
    console.error("Error deleting lender: ", error);
  }
};

export const handleTransaction = async (lenderId, transactionType, amount) => {
  const lenderRef = doc(db, 'lenders', lenderId);
  const lenderDoc = await getDoc(lenderRef);

  if (lenderDoc.exists()) {
    const lenderData = lenderDoc.data();
    const updatedAmountOwed = transactionType === 'pay' ? lenderData.amountOwed - amount : lenderData.amountOwed + amount;
    const updatedTransactionHistory = arrayUnion({
      type: transactionType,
      amount: amount,
      date: new Date().toISOString(),
    });

    await updateDoc(lenderRef, {
      amountOwed: updatedAmountOwed,
      transactionHistory: updatedTransactionHistory,
    });

    // Return updated lender data
    return { id: lenderId, ...lenderData, amountOwed: updatedAmountOwed };
  } else {
    console.error('No such lender found!');
    return null;
  }
};