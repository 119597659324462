import React, { useState, useEffect } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import {signUp , signIn } from './services/auth';
import { db, auth } from './firebase';
import { 
  addLenderToFirestore, 
  getLendersFromFirestore, 
  editLenderInFirestore, 
  markLenderAsPaidInFirestore, 
  markLenderAsUnpaidInFirestore, 
  deleteLenderFromFirestore,
  handleTransaction
} from './services/lenderService';
import Dashboard from './components/Dashboard';
import AddLender from './components/Add_lender';
import LenderTable from './components/LenderTable';
import UploadLenderExcel from './components/UploadLenderExcel';
import { Container, TextField, Button, Typography, Paper } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { setDoc, doc, getDoc } from 'firebase/firestore'; 
import SignUp from './components/SignUp';
import ExportExcel from './components/exportlender';
import ExistingLenderDropdown from './components/existingLenders';


function App() {
  const [lenders, setLenders] = useState([]);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [userName, setUserName] = useState('');
  const [userId, setUserId] = useState(''); // Add userId state

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      const isLoggedIn = !!user;
      setIsLoggedIn(isLoggedIn);
  
      if (isLoggedIn) {
        const userId = user.uid;
        setUserId(userId); // Set userId state
  
        // Subscribe to lenders only if user is logged in
        const unsubscribeFromLenders = getLendersFromFirestore(userId, setLenders);
  
        // Cleanup function for lenders subscription
        return () => {
          if (unsubscribeFromLenders) {
            unsubscribeFromLenders();
          }
        };
      } else {
        // If not logged in, reset lenders
        setLenders([]);
      }
    });
  
    // Cleanup for authentication listener
    return () => unsubscribe();
  }, [auth]); // Ensure `auth` is in the dependency array
  
  

  const addLender = async (newLender) => {
    if (auth.currentUser) {
      console.log("lender: ", lenders);
      const userId = auth.currentUser.uid;
      newLender.amountOwed = Number(newLender.amountOwed); // Ensure amount is a number
      setLenders((prevLenders) => [...prevLenders, newLender]);
      console.log("addLender setLenders function:", setLenders);
      await addLenderToFirestore(newLender, userId);
    }
  };

    const handleSignUp = async ({ name: fullName, username: userName, email, password }) => {
      try {
        //console.log("Hey I am in handlesignup");
        const userCredential = await signUp(email, password);
        const user = userCredential.user;
        //console.log("New User info : ", user);
        // Create a document in Firestore with user info
        await setDoc(doc(db, 'users', user.uid), {
          name: fullName, // Using the renamed variable
          username: userName, // Using the renamed variable
          email: email,
        });
        console.log("New User info : ", user);
        alert("Sign up successful!");
        // Optionally, update login state here if needed
      } catch (error) {
        alert(error.message);
      }
    };

    const handleSignIn = async () => {
      try {
        const userCredential = await signIn(email, password);
        const user = userCredential.user;
    
        // Fetch user data from Firestore
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserName(userData.name); // Save user's name to state
        } else {
          alert("No user data found!");
        }
        console.log("USERNAME: ", userDoc.data);
        setIsLoggedIn(true);
        alert("Sign in successful!");
      } catch (error) {
        alert(error.message);
      }
    };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setIsLoggedIn(false);
      setLenders([]);
      console.log("Logout setLenders function:", setLenders);
    } catch (error) {
      alert(error.message);
    }
  };

  const editLender = async (updatedLender) => {
    updatedLender.amountOwed = Number(updatedLender.amountOwed); // Ensure amount is a number
    setLenders((prevLenders) =>
      prevLenders.map((lender) =>
        lender.id === updatedLender.id ? updatedLender : lender
      )
    );
    console.log("edit lender setLenders function:", setLenders);

    await editLenderInFirestore(updatedLender.id, updatedLender);
  };

  const markLenderAsPaid = async (lenderId) => {
    await markLenderAsPaidInFirestore(lenderId);
    setLenders((prevLenders) =>
      prevLenders.map((lender) =>
        lender.id === lenderId ? { ...lender, isPaid: true } : lender
      )
    );
  };

  const markLenderAsUnpaid = async (lenderId) => {
    await markLenderAsUnpaidInFirestore(lenderId);
    setLenders((prevLenders) =>
      prevLenders.map((lender) =>
        lender.id === lenderId ? { ...lender, isPaid: false } : lender
      )
    );
  };

  const handleDelete = async (id) => {
    await deleteLenderFromFirestore(id); // Call the delete function
    setLenders((prevLenders) => prevLenders.filter((lender) => lender.id !== id));
  };

  const handleTransactionWrapper = async (lenderId, transactionType, amount) => {
    console.log("Processing transaction...");
    const updatedLender = await handleTransaction(lenderId, transactionType, amount);
    
    if (updatedLender) {
      setLenders((prevLenders) =>
        prevLenders.map((lender) =>
          lender.id === lenderId ? updatedLender : lender
        )
      );
    }
  };
  

  const theme = createTheme({
    typography: {
      h5: {
        fontFamily: 'Poppins, sans-serif', // Replace with your preferred font
        fontWeight: 'bold',
        letterSpacing: '0.1rem',
        textTransform: 'uppercase',
        color: '#3f51b5', // Change to your desired color
      },
    },
  });

  return (
    <Container component="main" maxWidth="xl">
      <Paper elevation={3} style={{ padding: '20px', margin: '20px', width: '90%', maxWidth: '800px' }}>
        {!isLoggedIn ? (
          <form noValidate>
            <ThemeProvider theme={theme}>
              <Typography variant="h5" align="center">
           Welcome to Udhaar!
             </Typography>
            </ThemeProvider>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSignIn}
              style={{ marginBottom: '10px' }}
            >
              Sign In
            </Button>
            <Button
        variant="outlined"
        color="primary"
        onClick={() => setShowSignUp(true)} // Show the SignUp component
      >
        New User
      </Button>

      {showSignUp && (
        <SignUp onSignUp={handleSignUp} />
      )}
          </form>
        ) : (
          <>
            <ThemeProvider theme={theme}>
              <Typography variant="h5" align="center">
             Udhaar Dashboard
             </Typography>
            </ThemeProvider>
            <Button
            variant="outlined"
            color="secondary"
            onClick={handleLogout}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
            }}
          >
            Logout
            
          </Button>
            <Dashboard lenders={lenders} />
            <AddLender addLender={addLender} />
            <ExistingLenderDropdown userId={userId} onTransaction={handleTransactionWrapper} />
            <LenderTable lenders={lenders} onEdit={editLender} onMarkPaid={markLenderAsPaid} onMarkUnpaid={markLenderAsUnpaid} onDelete = {handleDelete}/>
            <UploadLenderExcel />
            <ExportExcel lenders={lenders} />
          </>
        )}
      </Paper>
    </Container>
  );
}

export default App;
