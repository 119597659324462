// scripts/convertExcelToJson.js
import * as XLSX from 'xlsx';

export const convertExcelToJson = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      
      // Assuming the first row is the header
      const headers = jsonData[0];
      const result = jsonData.slice(1).map(row => {
        return {
          name: row[0], // Name
          amountOwed: row[1], // Amount Owed
          date: row[2], // Date
        };
      });
      resolve(result);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
};
