import React from 'react';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, 
  TableRow, Paper, Button, Dialog, DialogActions, 
  DialogContent, DialogTitle, TextField, Checkbox, FormControlLabel 
} from '@mui/material';

const LenderTable = ({ lenders = [], onEdit, onDelete }) => {
  const [editingLender, setEditingLender] = React.useState(null);
  const [formData, setFormData] = React.useState({ name: '', amountOwed: '', date: '', isPaid: false });

  const handleEdit = (lender) => {
    setEditingLender(lender);
    setFormData({ 
      ...lender, 
      date: lender.date ? lender.date.split('T')[0] : ''
    });
  };

  const handleSave = () => {
    const updatedLender = {
      ...formData,
      id: editingLender.id,
      amountOwed: Number(formData.amountOwed),
    };
    onEdit(updatedLender);
    setEditingLender(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleClose = () => {
    setEditingLender(null);
  };

  const handleDelete = () => {
    if (editingLender) {
      onDelete(editingLender.id);
      handleClose();
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Lender Name</TableCell>
              <TableCell>Amount Owed</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lenders.length > 0 ? (
              lenders.map((lender) => (
                <TableRow key={lender.id}>
                  <TableCell>{lender.name}</TableCell>
                  <TableCell>₹{lender.amountOwed}</TableCell>
                  <TableCell>{new Date(lender.date).toLocaleDateString()}</TableCell>
                  <TableCell>{lender.isPaid ? 'Paid' : 'Pending'}</TableCell>
                  <TableCell>
                    <Button onClick={() => handleEdit(lender)} variant="outlined">Edit</Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">No lenders available</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Edit Dialog */}
      <Dialog open={!!editingLender} onClose={handleClose}>
        <DialogTitle>Edit Lender</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            name="name"
            label="Lender Name"
            type="text"
            fullWidth
            value={formData.name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="amountOwed"
            label="Amount Owed"
            type="number"
            fullWidth
            value={formData.amountOwed}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="date"
            label="Date"
            type="date"
            fullWidth
            value={formData.date ? formData.date.split('T')[0] : ''}
            onChange={handleChange}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.isPaid}
                onChange={(e) => setFormData({ ...formData, isPaid: e.target.checked })}
                name="isPaid"
              />
            }
            label={formData.isPaid ? 'Mark as Unpaid' : 'Mark as Paid'}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Cancel</Button>
          <Button onClick={handleSave} color="primary">Save</Button>
          <Button onClick={handleDelete} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LenderTable;
