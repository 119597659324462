// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBivvY0L_tL_XoKHTLFl2j4MrEIjZ50-fo",
    authDomain: "lending-management-bc071.firebaseapp.com",
    projectId: "lending-management-bc071",
    storageBucket: "lending-management-bc071.appspot.com",
    messagingSenderId: "681570066248",
    appId: "1:681570066248:web:6c0bebfdb0c824bcb1dd7d",
    measurementId: "G-6DSYQ3TP6G"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };