import React, { useEffect, useState } from 'react';
import { TextField, Button, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getLendersFromFirestore } from '../services/lenderService';

const AddLender = ({ addLender }) => {
  const [name, setName] = useState('');
  const [amount, setAmount] = useState('');
  const [date, setDate] = useState(null);
  const [lenders, setLenders] = useState([]);
  const [selectedLender, setSelectedLender] = useState('');

  useEffect(() => {
    const unsubscribe = getLendersFromFirestore(setLenders); 
    return () => unsubscribe(); 
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if ((selectedLender || name) && amount && date) {
      addLender({
        name: selectedLender || name, 
        amountOwed: parseFloat(amount),
        date: date.toISOString(),
      });
      setName('');
      setAmount('');
      setDate(null);
      setSelectedLender(''); 
    } else {
      alert('Please fill in all fields.'); 
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ marginBottom: '20px' }}>
      {/* <FormControl fullWidth variant="outlined" margin="normal">
        <Select
          labelId="lender-select-label"
          value={selectedLender}
          onChange={(e) => {
            setSelectedLender(e.target.value);
            if (e.target.value) setName(''); // Clear name if a lender is selected
          }}
        >
          {lenders.map((lender) => (
            <MenuItem key={lender.id} value={lender.name}>
              {lender.name}
            </MenuItem>
          ))}
          <MenuItem value="">Add New Lender</MenuItem>
        </Select>
      </FormControl> */}
      
      {selectedLender === '' && (
        <TextField
          variant="outlined"
          label="Lender Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          fullWidth
          margin="normal"
        />
      )}
      
      <TextField
        variant="outlined"
        label="Amount Owed"
        type="number"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        required
        fullWidth
        margin="normal"
      />
      
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Select Date"
          value={date}
          onChange={(newValue) => setDate(newValue)}
          renderInput={(params) => <TextField {...params} required fullWidth margin="normal" />}
        />
      </LocalizationProvider>
      
      <Button type="submit" variant="contained" color="primary" style={{ marginTop: '10px' }}>
        Add Lender
      </Button>
    </form>
  );
};

export default AddLender;
