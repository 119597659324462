import React from 'react';
import * as XLSX from 'xlsx';
import { Button, Tooltip } from '@mui/material';
import { Save as SaveIcon } from '@mui/icons-material';

const ExportExcel = ({ lenders }) => {
  const handleExport = () => {
    if (!lenders || lenders.length === 0) {
      alert('No data to export');
      return;
    }

    // Create a new array with only the required fields
    const filteredLenders = lenders.map(({ name, isPaid, date, amountOwed }) => ({
      name,
      isPaid,
      date,
      amountOwed,
    }));

    // Convert filtered lenders data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(filteredLenders);
    const workbook = XLSX.utils.book_new();

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Lenders');

    // Generate buffer and save the file
    XLSX.writeFile(workbook, 'lenders_data.xlsx');
  };

  return (
    <Tooltip title="Export data to Excel" arrow>
      <Button
        variant="contained"
        color="primary"
        size="large"
        startIcon={<SaveIcon />}
        onClick={handleExport}
        style={{ marginTop: '20px' }}
      >
        Export to Excel
      </Button>
    </Tooltip>
  );
};

export default ExportExcel;
