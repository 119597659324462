import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { convertExcelToJson } from '../scripts/convertExcelToJson'; // Adjust path as necessary
import { auth } from '../firebase'; // Import auth
import { addLenderToFirestore } from '../services/lenderService'; // Adjust path as necessary

const UploadLenderExcel = () => {
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [jsonData, setJsonData] = useState([]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      handleReadFile(selectedFile); // Read the file immediately
    }
  };

  const handleReadFile = async (file) => {
    try {
      const data = await convertExcelToJson(file);
      console.log('Parsed data:', data); // Debugging line
      setJsonData(data);
      setOpen(true);
    } catch (error) {
      console.error('Error reading file:', error);
      alert('Failed to read the file. Please check the format.');
    }
  };

  const addLender = async (newLenders) => {
    if (auth.currentUser) {
      const userId = auth.currentUser.uid;

      for (const lender of newLenders) {
        lender.amountOwed = Number(lender.amountOwed); // Ensure amount is a number
        await addLenderToFirestore(lender, userId);
      }
    } else {
      alert("User not authenticated. Cannot add lenders.");
    }
  };

  const handleConfirmUpload = async () => {
    if (!jsonData || jsonData.length === 0) {
      alert('No data to upload. Please check the file.');
      return;
    }
  
    // Set default date and isPaid status
    const defaultDate = new Date().toISOString().split('T')[0]; // Current date in YYYY-MM-DD format
    const validData = jsonData.map(item => {
      // Convert date to proper format if it's a number or string
      const parsedDate = item.date ? new Date(item.date).toISOString().split('T')[0] : defaultDate;
  
      return {
        ...item,
        date: parsedDate, // Use parsed date
        isPaid: 0, // Set isPaid status to pending
      };
    }).filter(item => item.name && item.amountOwed); // Filter valid entries
  
    if (validData.length === 0) {
      alert('No valid entries found. Please check the file.');
      return;
    }
  
    try {
      await addLender(validData); // Assuming onUpload handles the Firebase upload
      alert('Data uploaded successfully!');
      setOpen(false);
      setFile(null);
      setJsonData([]);
    } catch (error) {
      console.error('Upload error:', error);
      alert('Error uploading data. Please try again.');
    }
  };
  

  const handleClose = () => {
    setOpen(false);
    setFile(null);
    setJsonData([]);
  };

  return (
    <div>
      <input
        accept=".xlsx, .xls"
        type="file"
        onChange={handleFileChange}
      />
      <Button variant="contained" onClick={() => handleReadFile(file)} disabled={!file}>
        Read File
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Upload</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to upload the data from this file?</p>
          <p>Data preview:</p>
          <ul>
            {jsonData.map((item, index) => (
              <li key={index}>
                {`${item.name || 'N/A'} - ₹${item.amountOwed || 'N/A'} - ${item.date || 'N/A'}`}
              </li>
            ))}
          </ul>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Cancel</Button>
          <Button onClick={handleConfirmUpload} color="primary">Upload</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UploadLenderExcel;
