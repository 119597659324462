import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const Dashboard = ({ lenders }) => {
  if (!lenders) return null;
  const totalAmountOwed = lenders.reduce((total, lender) => {
    return total + (!lender.isPaid ? Number(lender.amountOwed) : 0);
  }, 0);

  
  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Total Amount Owed
          </Typography>
          <Typography variant="h4" component="p" style={{ fontWeight: 'bold', color: '#3f51b5' }}>
            ₹{totalAmountOwed.toFixed(2)}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default Dashboard;
